import { Component, Input } from '@angular/core';
import { BaseInput } from '../../models';

@Component({
  selector: 'app-input-label',
  styles: [
    `
      .bold {
        font-weight: 600;
      }
    `,
  ],
  template: `
    <label
      for="{{ 'input' + name }}"
      class="label font-size-16"
      [class.bold]="field.required || field.inputType === 'list'"
      *ngIf="
        field.label &&
        field.inputType !== 'checkbox' &&
        field.inputType !== 'fieldset'
      "
      >{{ field.label | translate }}
      <span
        *ngIf="!field.required && !filter && field.inputType !== 'list'"
        translate="UTILS_FORM_LABEL_NOT_MANDATORY"
      ></span
    ></label>
  `,
})
export class InputLabelComponent {
  @Input() name: string;
  @Input() field: BaseInput;
  @Input() filter: boolean;
}
