import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { I18nService } from '@eng-ds/translate';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AutoUnsubscribe } from './core/components';

@Component({
  selector: 'adsp-multilingua-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends AutoUnsubscribe implements OnDestroy {
  constructor(
    private i18nService: I18nService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    super();
    this._titlePage();
  }

  private _titlePage(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data) {
          this.titleService.setTitle(
            this.i18nService.translate(data) + ' - Autorità di Sistema Portuale'
          );
        }
      });
  }
}
