import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@eng-ds/translate';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  AutocompleteInputComponent,
  CheckboxInputComponent,
  DateInputComponent,
  DividerInputComponent,
  FormComponent,
  FormInputComponent,
  FormInputsComponent,
  RadiosInputComponent,
  SelectInputComponent,
  TextareaInputComponent,
  TextboxInputComponent,
  ValidationInputMessagesComponent,
} from './components';
import { InputLabelComponent } from './components/input-label/input-label.component';
import { FileInputComponent } from './components/inputs/file-input/file-input.component';
import { NopInputComponent } from './components/inputs/nop-input/nop-input.component';
import {
  DatepickerHostDirective,
  FormColorStatusDirective,
} from './directives';
import { AddClearOptionPipe } from './pipes';

@NgModule({
  declarations: [
    FormInputsComponent,
    FormInputComponent,
    FormComponent,
    TextboxInputComponent,
    SelectInputComponent,
    NopInputComponent,
    TextareaInputComponent,
    DateInputComponent,
    FileInputComponent,
    CheckboxInputComponent,
    RadiosInputComponent,
    AutocompleteInputComponent,
    ValidationInputMessagesComponent,
    InputLabelComponent,
    AddClearOptionPipe,
    DatepickerHostDirective,
    FormColorStatusDirective,
    DividerInputComponent,
  ],
  exports: [
    ReactiveFormsModule,
    FormComponent,
    FileInputComponent,
    CheckboxInputComponent,
    RadiosInputComponent,
    TextboxInputComponent,
    TextareaInputComponent,
    FormInputsComponent,
    SelectInputComponent,
    InputLabelComponent,
    ValidationInputMessagesComponent,
    AutocompleteInputComponent,
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FormModule {}
