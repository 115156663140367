import { ApiConfig, BackendConfig } from '@eng-ds/api-client';

declare const Adsp: any;

const apiGeneralPath = '/apigeneral/1.0';

const backendApi: ApiConfig[] = [
  {
    name: 'getModuli',
    method: 'GET',
    url: apiGeneralPath + '/config/moduli',
  },
  {
    name: 'getLingue',
    method: 'GET',
    url: apiGeneralPath + '/lingua',
  },
  {
    name: 'getLingueCensite',
    method: 'GET',
    url: apiGeneralPath + '/lingua/list',
  },
  {
    name: 'getTraduzioni',
    method: 'GET',
    url: apiGeneralPath + '/lingua/getTraduzioni',
  },
  {
    name: 'createLingua',
    method: 'POST',
    url: apiGeneralPath + '/lingua/add',
  },
  {
    name: 'updateLingua',
    method: 'PATCH',
    url: apiGeneralPath + '/lingua/update/{id}',
  },
  {
    name: 'updateTraduzioni',
    method: 'PATCH',
    url: apiGeneralPath + '/lingua/updateTraduzioni',
  },
  {
    name: 'uploadTraduzioni',
    method: 'POST',
    url: apiGeneralPath + '/lingua/uploadTraduzioni',
  },
];

export const backendConfig: Omit<
  BackendConfig,
  'getApiConfig' | 'prepareUrl'
> = {
  baseUrl: Adsp.configs.get('baseApiUrl'),
  api: backendApi,
};
