import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@eng-ds/translate';
import { EmptyRouteComponent, LineClampComponent } from './components';
import { ColorStatusDirective } from './directives';
import { FormModule } from './form';
import { AssetUrlPipe } from './pipes';

@NgModule({
  imports: [CommonModule, FormModule, TranslateModule.forChild()],
  declarations: [
    AssetUrlPipe,
    ColorStatusDirective,
    LineClampComponent,
    EmptyRouteComponent,
  ],
  exports: [
    CommonModule,
    AssetUrlPipe,
    FormModule,
    ColorStatusDirective,
    TranslateModule,
    LineClampComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
