import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

declare const $: any;

@Component({
  selector: 'adsp-line-clamp',
  template: `
    <div #box class="box">
      <ng-content></ng-content>
    </div>
    <!-- <a href="javascript:void(0)" *ngIf="enabled" (click)="toggle()">
      {{ opened ? 'Show Less' : 'Show More' }}
    </a> -->

    <a
      #popover
      href="javascript:void(0)"
      data-trigger="manual"
      [attr.data-content]="content.nativeElement.innerText"
      *ngIf="enabled"
      (click)="toggle()"
    >
      {{ opened ? 'Vedi meno' : 'Vedi tutto' }}
    </a>

    <!-- <agid-icon
      *ngIf="enabled"
      size="small"
      icon="eye"
      status="primary"
      svg-sprite-path="/assets/svg/adsp_sprite.svg"
    ></agid-icon> -->
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .box {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        visibility: hidden;
      }
    `,
  ],
})
export class LineClampComponent implements AfterContentInit {
  @ContentChild('content') content: ElementRef;

  @ViewChild('box') box: ElementRef;
  @ViewChild('popover') popover: ElementRef;

  @Input() row = 1;

  enabled = false;
  opened = false;
  private boxHeight: number;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterContentInit() {
    setTimeout(() => {
      // calc the content height and the box height
      const contentElm = this.content.nativeElement;
      const contentHeight = parseInt(
        window
          .getComputedStyle(contentElm, undefined)
          .getPropertyValue('height'),
        10
      );
      this.boxHeight = getLineHeight(contentElm) * this.row;
      if (contentHeight > this.boxHeight) {
        this.enable();
        this.cd.detectChanges();
      }
      this.box.nativeElement.style.visibility = 'visible';
    });
  }

  toggle() {
    this.opened ? this.close() : this.open();
  }

  /**
   * show more/less
   */
  private enable() {
    this.enabled = true;
    this.close();
    this.clamp();
  }

  private open() {
    this.opened = true;
    $(this.popover?.nativeElement)?.popover('show');
    // enable clamp
    // this.box.nativeElement.style['-webkit-line-clamp'] = 'initial';
    // this.box.nativeElement.style.height = 'auto';
  }

  private close() {
    this.opened = false;
    $(this.popover?.nativeElement)?.popover('hide');
    // disable clamp
    // this.clamp();
  }

  private clamp() {
    this.box.nativeElement.style['-webkit-line-clamp'] = this.row;
    this.box.nativeElement.style.height = this.boxHeight + 'px';
  }
}

function getLineHeight(element: HTMLElement): number {
  let lineHeight = parseInt(
    window.getComputedStyle(element, undefined).getPropertyValue('lineHeight'),
    10
  );
  if (isNaN(lineHeight)) {
    const clone = element.cloneNode() as HTMLElement;
    clone.innerHTML = '<br>';
    element.appendChild(clone);
    lineHeight = clone.clientHeight;
    element.removeChild(clone);
  }
  return lineHeight;
}
