import { Component, Input } from '@angular/core';
import { CheckboxInput } from '../../../models/inputs/checkbox-input';
import { BaseInputComponent } from '../base-input/base-input.component';

@Component({
  selector: 'app-checkbox-input',
  template: `
    <div class="form-check">
      <input
        id="{{ 'input' + name }}"
        type="checkbox"
        [formControl]="control"
      />
      <label for="{{ 'input' + name }}">>{{ control.label | translate }}</label>
    </div>
  `,
})
export class CheckboxInputComponent extends BaseInputComponent {
  @Input() name: string;
  @Input() control: CheckboxInput;
}

/**
 * <nb-checkbox [status]="control.colorStatus" [formControl]="control"
      >{{ control.label | translate }}
    </nb-checkbox>
 */
