import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

declare type NbComponentStatus =
  | 'basic'
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'control';

export interface ActionFooter {
  label: string;
  class?: string[];
  position: 'left' | 'center' | 'right';
  outline?: boolean;
  disabled?: Observable<boolean>;
  status?: NbComponentStatus;
  click: () => void;
  icon?: { icon: string; pack?: string; position?: 'left' | 'right' } | string;
}

@Injectable()
export class ActionsFooterService {
  actions: BehaviorSubject<ActionFooter[]> = new BehaviorSubject([]);
  actions$ = this.actions.asObservable();

  add(actions: ActionFooter[]): void {
    this.actions.next([...actions]);
  }

  destroy(): void {
    this.actions.next([]);
  }
}
