export const IT = {
  TITLE_PAGE_MULTILINGUA_LIST: 'Lista lingue',
  TITLE_PAGE_MULTILINGUA_DETAIL: 'Gestisci voci dizionario',
  TITLE_PAGE_MULTILINGUA_EDIT: 'Modifica lingua',
  TITLE_PAGE_MULTILINGUA_NEW: 'Nuova lingua',

  MULTILINGUA_BREADCRUMB_HOME: 'Home',
  MULTILINGUA_BREADCRUMB_ADMIN: 'Amministrazione',
  MULTILINGUA_BREADCRUMB_MANAGE: 'Gestione multilingua',
  MULTILINGUA_BREADCRUMB_NEW: 'Nuova lingua',
  MULTILINGUA_BREADCRUMB_EDIT: 'Modifica lingua',

  MULTILINGUA_LISTA_HEADER_TITLE: 'Lista Lingue',
  MULTILINGUA_LISTA_RESULTS: 'risultati trovati',
  MULTILINGUA_LISTA_NEW_LABEL: 'Nuova lingua',
  MULTILINGUA_LISTA_TABLE_TH1: 'Nome lingua',
  MULTILINGUA_LISTA_TABLE_TH2: 'Codice lingua',
  MULTILINGUA_LISTA_TABLE_TH3: 'Stato',
  MULTILINGUA_LISTA_TABLE_ENABLED: 'Abilitata',
  MULTILINGUA_LISTA_TABLE_DISABLED: 'Disabilitata',
  MULTILINGUA_LISTA_TABLE_BUTTONS_ENABLE_TITLE: 'Gestisci abilitazione',
  MULTILINGUA_LISTA_TABLE_BUTTONS_EDIT_TITLE: 'Modifica',

  MULTILINGUA_ACTIONS_FOOTER_SAVE: 'Salva',
  MULTILINGUA_ACTIONS_FOOTER_SAVE_ENABLE: 'Salva e Abilita',
  MULTILINGUA_ACTIONS_FOOTER_SAVE_PUBLISH: 'Salva e Pubblica',
  MULTILINGUA_ACTIONS_FOOTER_CANCEL: 'Annulla',

  MULTILINGUA_NEW_SUCC_TITLE: 'Creazione',
  MULTILINGUA_NEW_SUCC_TEXT: 'La lingua è stata creata con successo.',

  MULTILINGUA_NEW_ERR_TITLE: 'Errore Creazione',
  MULTILINGUA_NEW_ERR_TEXT:
    'Si è verificato un errore durante la creazione della lingua.',

  MULTILINGUA_EDIT_NAME: 'Nome lingua',
  MULTILINGUA_EDIT_NAME_PLACEHOLDER: 'Inserisci nome nuova lingua',

  MULTILINGUA_EDIT_ID: 'Codice lingua',
  MULTILINGUA_EDIT_ID_PLACEHOLDER: 'Inserisci codice alfanumerico',

  MULTILINGUA_EDIT_SUCC_TITLE: 'Modifica',
  MULTILINGUA_EDIT_SUCC_TEXT: 'La lingua è stata modificata con successo.',

  MULTILINGUA_EDIT_ERR_TITLE: 'Errore Modifica',
  MULTILINGUA_EDIT_ERR_TEXT:
    'Si è verificato un errore durante la modifica della lingua.',

  MULTILINGUA_RESOLVER_LANG_ERR_TITLE: 'Errore',
  MULTILINGUA_RESOLVER_LANG_ERR_TEXT:
    'Si è verificato un errore durante il recupero delle lingue censite.',

  MULTILINGUA_RESOLVER_ID_ERR_TITLE: 'Errore',
  MULTILINGUA_RESOLVER_ID_ERR_TEXT: 'ID non consentito.',

  MULTILINGUA_MOD_RESOLVER_LANG_ERR_TITLE: 'Errore',
  MULTILINGUA_MOD_RESOLVER_LANG_ERR_TEXT:
    'Si è verificato un errore durante il recupero dei moduli.',

  MULTILINGUA_LIST_ERROR_TITLE: 'Errore',
  MULTILINGUA_LIST_ERROR_TEXT:
    'Si è verificato un errore durante il recupero delle lingue censite.',

  MULTILINGUA_ENABLE_TITLE: 'Abilita lingua',
  MULTILINGUA_DISABLE_TITLE: 'Disabilita lingua',
  MULTILINGUA_ENABLE_TEXT: 'Confermi di voler abilitare la lingua ',
  MULTILINGUA_DISABLE_TEXT: 'Confermi di voler disabilitare la lingua ',
  MULTILINGUA_TOGGLE_TEXT_END: '?',

  MULTILINGUA_TOGGLE_ERR_TITLE: 'Errore',
  MULTILINGUA_DISABLED_ERR_TEXT:
    'Si è verificato un errore durante l\'abilitazione.',
  MULTILINGUA_ENABLED_ERR_TEXT:
    'Si è verificato un errore durante la disabilitazione.',

  MULTILINGUA_TOGGLE_SUCC_TITLE: 'Successo',
  MULTILINGUA_DISABLED_SUCC_TEXT: 'La lingua è stata disabilitata.',
  MULTILINGUA_ENABLED_SUCC_TEXT: 'La lingua è stata abilitata.',

  MULTILINGUA_DETAIL_TITLE: 'Gestisci voci dizionario',
  MULTILINGUA_DETAIL_FILE_LABEL: 'Carica file dizionario',
  MULTILINGUA_DETAIL_FILE_DOWNLOAD_LABEL: 'Scarica file dizionario',
  MULTILINGUA_DETAIL_FILE_DOWNLOAD_BTN: 'Scarica file',
  MULTILINGUA_DETAIL_DOWNLOAD_BTN: 'Scarica file',
  MULTILINGUA_DETAIL_FILE_SEARCH_BTN: 'Scegli file',
  MULTILINGUA_DETAIL_UPLOAD_BTN: 'Carica file',
  MULTILINGUA_DETAIL_SELECT_MODULE: 'Seleziona modulo',
  MULTILINGUA_DETAIL_SELECT_PLACEHOLDER: 'Modulo',
  MULTILINGUA_DETAIL_DESC_LABEL: 'Descrizione voce',
  MULTILINGUA_DETAIL_DESC_KEY: 'Chiave',

  MULTILINGUA_DETAIL_INPUT_LABEL: 'Testo voce',
  MULTILINGUA_DETAI_INPUT_PLACEHOLDER: 'Esempio voce',

  MULTILINGUA_DETAIL_UPDATE_SUCC_TITLE: 'Successo',
  MULTILINGUA_DETAIL_UPDATE_SUCC_TEXT:
    'Le traduzioni sono state aggiornate con successo.',

  MULTILINGUA_DETAIL_UPDATE_ERR_TITLE: 'Errore',
  MULTILINGUA_DETAIL_UPDATE_ERR_TEXT:
    'Si è verificato un errore durante l\'aggiornamento delle traduzioni.',

  MULTILINGUA_DETAIL_UPDATE_PUB_SUCC_TITLE: 'Successo',
  MULTILINGUA_DETAIL_UPDATE_PUB_SUCC_TEXT:
    'Le traduzioni sono state aggiornate e pubblicate con successo.',

  MULTILINGUA_DETAIL_UPDATE_PUB_ERR_TITLE: 'Errore',
  MULTILINGUA_DETAIL_UPDATE_PUB_ERR_TEXT:
    'Si è verificato un errore durante l\'aggiornamento e pubblicazione delle traduzioni.',

  MULTILINGUA_DETAIL_UPLOAD_ERR_TITLE: 'Errore',
  MULTILINGUA_DETAIL_UPLOAD_ERR_TEXT:
    'Si è verificato un errore durante il caricamento del file JSON.',

  MULTILINGUA_DETAIL_MODAL_TITLE: 'Traduzioni',
  MULTILINGUA_DETAIL_MODAL_CLOSE: 'Chiudi',
  MULTILINGUA_DETAIL_NAV_INSERISCI: 'Inserite',
  MULTILINGUA_DETAIL_NAV_INSERISCI_PLACEHOLDER: 'Nessuna traduzione inserita',
  MULTILINGUA_DETAIL_NAV_MANCANTI: 'Scartate',
  MULTILINGUA_DETAIL_NAV_MANCANTI_PLACEHOLDER: 'Nessuna traduzione scartata',
  MULTILINGUA_DETAIL_NAV_ASSENTI: 'Mancanti',
  MULTILINGUA_DETAIL_NAV_ASSENTI_PLACEHOLDER: 'Nessuna traduzione assente',

  TEXT_TRANSLATE_LABEL: 'Text label',
  TEXT_TRANSLATE_PLACEHOLDER: 'Text placeholder',

  UTILS_TOASTR_ERROR_GENERIC_TITLE: 'Errore',
  UTILS_TOASTR_ERROR_GENERIC_MESSAGE: 'C\'è stato un errore, riprova più tardi',

  FORM_SAVE: 'Salva',
  FORM_FILTER: 'Filtra',
  FORM_RESET: 'Cancella',

  COMPONENT_FORM_TITLE: 'Componente form',
  SINGLE_INPUTS_TITLE: 'Form singoli input',

  UTILS_CONFIRM: 'Conferma',
  UTILS_CANCEL: 'Annulla',
  UTILS_AUTOCOMPLETE_INSERT_MIN_CHARACTERS: 'Inserire almeno 3 caratteri',
  UTILS_AUTOCOMPLETE_LOADING: 'Caricamento...',
  UTILS_AUTOCOMPLETE_ITEMS_NOT_FOUND: 'Nessun elemento trovato',
  UTILS_AUTOCOMPLETE_TYPE_TO_SEARCH: 'Digita per ricercare',

  VALIDATIONS_REQUIRED: 'Campo richiesto',
  UTILS_FORM_LABEL_NOT_MANDATORY: '(facoltativo)',

  FILE_INPUT_REMOVE: 'Cambia file',
};
